import { FC } from 'react';
import IconProps from './IconProps';

const SortAscendingIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM14 2C14.5523 2 15 1.55228 15 1C15 0.447715 14.5523 0 14 0V2ZM1 4.00009C0.447715 4.00009 0 4.4478 0 5.00009C0 5.55237 0.447715 6.00009 1 6.00009V4.00009ZM10 6.00009C10.5523 6.00009 11 5.55237 11 5.00009C11 4.4478 10.5523 4.00009 10 4.00009V6.00009ZM1 7.99902C0.447715 7.99902 0 8.44674 0 8.99902C0 9.55131 0.447715 9.99902 1 9.99902V7.99902ZM7 9.99902C7.55228 9.99902 8 9.55131 8 8.99902C8 8.44674 7.55228 7.99902 7 7.99902V9.99902ZM10.2928 8.29272C9.90223 8.68325 9.90223 9.31641 10.2928 9.70694C10.6833 10.0975 11.3164 10.0975 11.707 9.70694L10.2928 8.29272ZM15.707 5.70694C16.0975 5.31641 16.0975 4.68325 15.707 4.29272C15.3164 3.9022 14.6833 3.9022 14.2928 4.29272L15.707 5.70694ZM15.7071 4.29272C15.3165 3.9022 14.6834 3.9022 14.2928 4.29272C13.9023 4.68325 13.9023 5.31641 14.2928 5.70694L15.7071 4.29272ZM18.2928 9.70694C18.6834 10.0975 19.3165 10.0975 19.7071 9.70694C20.0976 9.31641 20.0976 8.68325 19.7071 8.29272L18.2928 9.70694ZM15.9998 4.99983C15.9998 4.44754 15.5521 3.99983 14.9998 3.99983C14.4476 3.99983 13.9998 4.44754 13.9998 4.99983H15.9998ZM13.9998 16.9998C13.9998 17.5521 14.4476 17.9998 14.9998 17.9998C15.5521 17.9998 15.9998 17.5521 15.9998 16.9998H13.9998ZM1 2H14V0H1V2ZM1 6.00009H10V4.00009H1V6.00009ZM1 9.99902H7V7.99902H1V9.99902ZM11.707 9.70694L15.707 5.70694L14.2928 4.29272L10.2928 8.29272L11.707 9.70694ZM14.2928 5.70694L18.2928 9.70694L19.7071 8.29272L15.7071 4.29272L14.2928 5.70694ZM13.9998 4.99983V16.9998H15.9998V4.99983H13.9998Z"
        fill="#3E2234"
      />
    </svg>
  );
};

export default SortAscendingIcon;
